import React, {Component} from 'react';
import {Route, NavLink, HashRouter} from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home.js';
import Paintings from './components/Paintings/Paintings.js';
import Illustrations from './components/Illustrations/Illustrations.js';
import About from './components/About/About.js';

class App extends Component{
  render() {
    return (
      <div className="App">
        <HashRouter>
          <div className="App-components">
            <Route exact path="/" component={Home}/>
            <Route path="/paintings" component={Paintings}/>
            <Route path="/illustrations" component={Illustrations}/>
            <Route path="/about" component={About}/>
          </div>
        </HashRouter>
      </div>
    );
  }
}

export default App;
