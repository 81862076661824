import React, {Component} from 'react';
import {Route, NavLink, HashRouter} from 'react-router-dom';
import './Illustrations.css';
import logo from './edlogo.png';

class Illustrations extends Component {
    render() {
        return (
            <div className="Component">
                <img src={logo} className="nav-logo" alt="edlogo" />
                <HashRouter>
                    <ul className="Component-nav">
                        <li><NavLink to="/">HOME</NavLink></li>
                        <li><NavLink to="/paintings">PAINTINGS</NavLink></li>
                        <li><NavLink to="/illustrations">ILLUSTRATIONS</NavLink></li>
                        <li><NavLink to="/about">ABOUT</NavLink></li>
                    </ul>
                </HashRouter>

                <div className="Component-content">
                    <h3>Ed's illustration content</h3>
                    <h2>coming soon</h2>
                </div>
            </div>
        );
    }
  }
  
  export default Illustrations;