import React, {Component} from 'react';
import {Route, NavLink, HashRouter} from 'react-router-dom';
import logo from './edlogo.png';
import './Home.css';

class Home extends Component {
    render() {
        return (
            <div className="Home">
        <div className="border">
        <div className="content">
            <header className="header">
              <h1>ED</h1>
              <img src={logo} className="logo" alt="edlogo" />
              <h1>HALLMARK</h1>
            </header>
            <HashRouter>
              <ul className="Home-nav">
                <li><NavLink to="/paintings">PAINTINGS</NavLink></li>
                <li><NavLink to="/illustrations">ILLUSTRATIONS</NavLink></li>
                <li><NavLink to="/about">ABOUT</NavLink></li>
              </ul>
            </HashRouter>
        </div>
        </div>
      </div>
        );
    }
  }
  
  export default Home;